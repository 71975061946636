import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { Table } from 'react-materialize';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { obtenerUsuarios, agregarUsuarios, actualizarUsuarios } from '../services/api.js';
import { TableHeader, Pagination } from "../components/DataTable";
import Spinner from '../components/Spinner';


const Usuarios = () => {

  const [idUsuarios, setIdUsuarios] = useState('');
  const [nombreCorto, setNombreCorto] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [rol, setRol] = useState('');
  const [status, setStatus] = useState('');
  const [dataUsuarios, setDataUsuarios] = useState([]);

  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [loading, setLoading] = useState(false);

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const headers = [
    { name: "Nombre", field: "username", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Rol", field: "roles", sortable: false },
    { name: "Estatus", field: "estatus", sortable: false },
    { name: "Acciones", field: "actions", sortable: false }
  ];

  const dataListUsuarios = useMemo(() => {
    let computedComments = dataUsuarios;
    setTotalItems(computedComments.length);
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [dataUsuarios, currentPage, sorting]);

  useEffect(() => {
    let user = localStorage.getItem("username")
    if (user === "") {
      //history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      //history.push('/index');
    }

    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }
    materializeStylos();
  }, [])


  const materializeStylos = () => {
    var elems = document.querySelectorAll('.modal');
    var el = document.querySelectorAll('select');
    var elemDP = document.querySelectorAll('.datepicker');
    //var elemSN = document.querySelectorAll('.sidenav');
    M.Datepicker.init(elemDP, { container: 'body', format: "yyyy-mm-dd", onSelect: function (date) { } });
    M.Modal.init(elems, {});
    M.FormSelect.init(el, {});
    //M.Sidenav.init(elemSN, {});
  }

  useEffect(() => {
    localStorage.setItem("dataUsuarios", JSON.stringify(dataUsuarios));
    setDataUsuarios(dataUsuarios);
  }, [dataUsuarios])

  useEffect(() => {
    var el = document.querySelectorAll('select');
    var instanceS = M.FormSelect.init(el, {},);
  }, [rol, status])

  useEffect(() => {
    obtenerListaUsuarios();
  }, [])

  const obtenerListaUsuarios = async () => {
    setLoading(true);
    obtenerUsuarios(localStorage.getItem("token")).then((data) => {
      if (data.status === 200) {
        setDataUsuarios(data.data.payload.users)
      }
    }).catch(e => {
      console.log("algun error" + e);
      if (e.includes("401")) {
        M.toast({ html: 'Tiempo de sesión terminado' });
        setTimeout(() => {
          //history.push('/index');
        }, 200);
      }
    })
    setTimeout(() => {
      setLoading(false);
    }, 3000);

  }

  const registrarUser = () => {
    setLoading(true);
    if (nombreCorto === "" || email === "" || rol === "" || status === "" || password === "") {
      M.toast({ html: 'Falta llenar uno o más datos' });
      setLoading(false);
    } else {
      if (password === password2) {
        setTimeout(() => {
          agregarUsuarios(nombreCorto, password, email, rol, status, localStorage.getItem("token"));
          var elems = document.querySelectorAll('.modal');
          var instance = M.Modal.init(elems, {});
          M.toast({ html: 'Se han guardado los datos' });
          setLoading(false);
        }, 300);
      } else {
        M.toast({ html: 'No coinciden las contraseñas' });
        setLoading(false);
      }
    }
  }

  const actualizarUser = () => {
    setLoading(true);
    if (nombreCorto === "" || email === "" || rol === "" || status === "" || password === "") {
      M.toast({ html: 'Falta llenar uno o más datos' });
      setLoading(false);
    } else {
      if (password === password2) {
        setTimeout(() => {
          actualizarUsuarios(idUsuarios, nombreCorto, password, email, rol, status, localStorage.getItem("token"));
          var elems = document.querySelectorAll('.modal');
          var instance = M.Modal.init(elems, {});
          M.toast({ html: 'Se han guardado los datos' });
          setLoading(false);
        }, 300);
      } else {
        M.toast({ html: 'No coinciden las contraseñas' });
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">
        <br /><br />
        <div className="col s12 m11 z-depth-3 mzauto extra_pdd">
          <div className="col s12 jumbotron clear_float">
            <h4>Listado de usuarios</h4>
            <p className="lead">Ve el listado de usuarios, edita o crea nuevos usuarios.</p>
            <hr className="my-4" />
          </div>
          <Table>
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
            <tbody>
              {(dataUsuarios.length > 0) ?
                dataListUsuarios.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.username}</td>
                    <td><a href={"mailto:" + item.email}>{item.email}</a></td>
                    <td>{item.roles}</td>
                    <td>{item.enabled ? "Activo" : "Inactivo"}</td>
                    <td>
                      <button style={{ backgroundColor: "white", border: "none", cursor: "pointer" }} type="button"
                        onClick={e => {
                          setIdUsuarios(item.id)
                          setNombreCorto(item.nombre)
                          setEmail(item.email)
                          setRol(item.rol)
                          setStatus(!item.obsolete);
                          let btn = document.getElementById(`btn-modal-open${index}`)
                          btn.click();
                        }}>
                        <span className="new badge hoverable">EDITAR</span></button>
                      <a hidden href="#modalEditUser" id={`btn-modal-open${index}`} className="modal-trigger"><span className="new badge hoverable"></span></a>
                    </td>
                  </tr>
                ))
                :
                null
              }
            </tbody>
          </Table>
          <br></br>

          <div className="row">
            <div className="col s12 ">
              <div className="centrarDiv">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalAddUser" className="modal modal-fixed-footer" >
        <form id="modalAddUserForm" className="col s12">
          <div className="modal-content">
            <h4>Nuevo Usuario</h4>

            <div className="row">

              <div className="row">

                <div className="input-field col s12 m6">
                  <input placeholder="La Empresa" name="nombreCorto" defaultValue="" id="last_name" type="text" className="validate" onChange={e => setNombreCorto(e.target.value)} />
                  <label htmlFor="last_name">Nombre completo</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="contacto@empresa.com" validate="true" name="correoElectronico" defaultValue="" id="email" type="email" className="validate" onChange={e => setEmail(e.target.value)} />
                  <label htmlFor="email">Correo electrónico</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="nueva contraseña" validate="true" name="password" value={password} id="password" type="password" className="validate" onChange={e => setPassword(e.target.value)} />
                  <label htmlFor="email">Contraseña</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="nueva contraseña" validate="true" name="password" value={password2} id="password2" type="password" className="validate" onChange={e => setPassword2(e.target.value)} />
                  <label htmlFor="email">Repetir Contraseña</label>
                </div>

                <div className="input-field col s12 m6" >
                  <select name="status" onChange={e => setRol(e.target.value)}>
                    <option disabled>Seleccione</option>
                    <option value="ROLE_SUPER_ADMINISTRADOR">Administrador Master</option>
                    <option value="ROLE_ADMINISTRADOR">Administrador</option>
                    <option value="ROLE_OTORGANTE">Otorgante</option>
                  </select>
                  <label>Rol</label>
                </div>

                <div className="input-field col s12 m6" >
                  <select name="status" onChange={e => setStatus(e.target.value)}>
                    <option disabled>Seleccione</option>
                    <option value="false">Inactivo</option>
                    <option value="true">Activo</option>
                  </select>
                  <label>Status</label>
                </div>

              </div>

            </div>

          </div>
          <div className="modal-footer">
            <button className="modal-close waves-effect waves-green btn-flat" type="button">Cancelar</button>
            <button className="waves-effect waves-green btn" onClick={registrarUser} type="button" >Agregar</button>
          </div>
        </form>
      </div>


      <div id="modalEditUser" className="modal modal-fixed-footer" >
        <form id="modalEditUserForm" className="col s12">
          <div className="modal-content">
            <h4>Editar Usuario</h4>

            <div className="row">

              <div className="row">

                <div className="input-field col s12 m6">
                  <input placeholder="La Empresa" name="nombreCorto" value={nombreCorto} id="last_name" type="text" className="validate" onChange={e => setNombreCorto(e.target.value)} />
                  <label htmlFor="last_name">Nombre completo</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="contacto@empresa.com" validate="true" name="correoElectronico" value={email} id="email" type="email" className="validate" onChange={e => setEmail(e.target.value)} />
                  <label htmlFor="email">Correo electrónico</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="nueva contraseña" validate="true" name="password" value={password} id="password" type="password" className="validate" onChange={e => setPassword(e.target.value)} />
                  <label htmlFor="email">Contraseña</label>
                </div>

                <div className="input-field col s12 m6">
                  <input placeholder="nueva contraseña" validate="true" name="password" value={password2} id="password2" type="password" className="validate" onChange={e => setPassword2(e.target.value)} />
                  <label htmlFor="email">Repetir Contraseña</label>
                </div>

                <div className="input-field col s12 m6" >
                  <select name="status" onChange={e => setRol(e.target.value)} value={rol}>
                    <option disabled>Seleccione</option>
                    <option value="ROLE_SUPER_ADMINISTRADOR">Administrador Master</option>
                    <option value="ROLE_ADMINISTRADOR">Administrador</option>
                    <option value="ROLE_OTORGANTE">Otorgante</option>
                  </select>
                  <label>Status</label>
                </div>

                <div className="input-field col s12 m6" >
                  <select name="status" onChange={e => setStatus(e.target.value)} value={status}>
                    <option disabled>Seleccione</option>
                    <option value="false">Inactivo</option>
                    <option value="true">Activo</option>
                  </select>
                  <label>Status</label>
                </div>

              </div>

            </div>

          </div>
          <div className="modal-footer">
            <button className="modal-close waves-effect waves-green btn-flat" type="button">Cancelar</button>
            <button className="waves-effect waves-green btn" onClick={actualizarUser} type="button" >Guardar</button>
          </div>
        </form>
      </div>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>

    </div>

  )
}



export default Usuarios
