import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { obtenerIceServers, renovarToken } from '../services/data';
import {
    revisarEstadoTurno, asignarLLamadaAgente, enviarVeredictoTurno, finalizarLLamadaAgente, obtenerValidacionesTurno,
    getDocumentsZipClient, getDocumentsClient, compararRostroCliente, obtenerValidacionRenapoTurno
} from '../services/api.js';
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import Spinner from '../components/Spinner';
import M from 'materialize-css';
import { Tabs, Tab, Modal, Button } from 'react-materialize';
import { saveAs } from 'file-saver';
import { evento } from '../services/data';
import { mapearRespuesta, mapearError } from '../utilities/response/map_response';
import useNavigatorOnLine from '../utilities/isNavigatorOnline'

var socket = null;
var myPeerConnection = null;

const Videollamada = () => {
    const history = useHistory();
    const location = useLocation();
    const isOnline = useNavigatorOnLine();
    const [stream, setStream] = useState();
    const [newStream, setNewStream] = useState(false);
    const [loading, setLoading] = useState(true);
    const [documentos, setDocumentos] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [veredicto, setVeredicto] = useState('');
    const [src, setSrc] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [score, setScore] = useState({ text: "STATUS", color: "grey" });
    const [ine, setIne] = useState({ text: "INE", color: "grey" });
    const [renapo, setRenapo] = useState({ text: "RENAPO", color: "grey" });
    const [captura, setCaptura] = useState({ text: "CAPTURA", color: "grey" });
    const [validacionRenapo, setValidacionRenapo] = useState('');
    const [validacionIne, setValidacionIne] = useState('');
    const [nombreCliente, setNombreCliente] = useState('');
    const [isAudioEnable, setIsAudioEnable] = useState(true);
    const [isVideoEnable, setIsVideoEnable] = useState(true);
    const [estadoConexion, setEstadoConexion] = useState('');
    const myVideo = useRef();
    const userVideo = useRef();
    let objetoConfiguracion = {
        uuidUser: location.state?.objetoTurno.clienteUuid,
        transaccion: location.state?.objetoTurno.clienteTransaccion,
        uuidOtorgante: location.state?.objetoTurno.otorganteUuid
    };

    useEffect(() => {
        asignarTurno();
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        const uuidUser = location.state?.objetoTurno.clienteUuid;
        obtenerDocumentosCliente(uuidUser, transaccion);
        return () => {
            let localVideo = myVideo.current;
            if (localVideo) {
                localVideo.srcObject.getTracks().forEach(track => track.stop());
                localVideo.removeAttribute("src");
            }
        }
    }, []);

    useEffect(() => {
        if (isOnline) {
            evento('Estado navegador', 'Administrador - Videollamada', { conexion: isOnline }, true, objetoConfiguracion);
            if (!estadoConexion) {
                setEstadoConexion('conectado');
            } else if ('desconectado') {
                setEstadoConexion('restablecida');
            }
        } else {
            setEstadoConexion('desconectado');
            //console.log('Estado navegador', isOnline);
            M.toast({ html: 'Se perdió la conexión del navegador' });
            //evento('Estado navegador', 'Administrador - Videollamada', { conexion: isOnline }, false, objetoConfiguracion);
        }
        return () => { };
    }, [isOnline]);

    useEffect(() => {
        if (estadoConexion === 'restablecida') {
            asignarTurno(true);
        }
        return () => { };
    }, [estadoConexion]);

    const startCall = async () => {
        try {
            const response = await obtenerIceServers();
            if (response) {
                const { data, status } = response;
                if (status && status === 200) {
                    //console.log("response", data);
                    evento('Generar Ice Servers', 'Administrador - Success', mapearRespuesta(status, data), true, objetoConfiguracion);
                    let nuevosServer = data.v.iceServers;
                    //console.log('linkCall', location.state?.objetoTurno.linkCall);
                    socket = new WebSocket(location.state?.objetoTurno.linkCall);
                    // Connection opened
                    socket.addEventListener('open', (event) => {
                        evento('Conexión abierta', 'Administrador - Videollamada', { event: event }, true, objetoConfiguracion);
                        //console.log('OPEN_EVENT', event);
                        setTimeout(() => {
                            invite(nuevosServer)
                        }, 100);
                    });
                    // Listen for messages
                    socket.addEventListener('message', (event) => {
                        event.data.text().then(async (res) => {
                            //console.log('MESSAGE_RESPONSE', JSON.parse(res));
                            const dato = JSON.parse(res);
                            evento('Respuesta del mensaje', 'Administrador - Videollamada', dato, true, objetoConfiguracion);
                            //console.log("TYPE", dato.type);
                            switch (dato.type) {
                                case "SessionDescription":
                                    /* console.log("SessionDescription", dato.payload);
                                    console.log('signalingState', myPeerConnection.signalingState);
                                    console.log("SessionDescription", dato.payload.type); */
                                    if (dato.payload.type === "answer") {
                                        await myPeerConnection.setRemoteDescription(dato.payload).catch(error => error);
                                    }
                                    //console.log("peerConnA",myPeerConnection);
                                    break;
                                case "IceCandidate":
                                    //console.log("IceCandidate", dato);
                                    myPeerConnection.addIceCandidate({
                                        candidate: dato.payload.sdp,
                                        sdpMid: dato.payload.sdpMid, // don't make it up, you get this in onicecandidate
                                        sdpMLineIndex: dato.payload.sdpMLineIndex, // don't make it up, you get this in onicecandidate
                                    }).catch(error => error)
                                    break;
                                default:
                                    break;
                            }
                        });

                    });


                }
            }
        } catch (error) {
            let errorMapeado = mapearError(error);
            evento('Generar Ice Servers', errorMapeado.tipoError, errorMapeado.objetoError, false);
            startCall();
        }
    }

    const invite = async (servers) => {
        if (myPeerConnection) {
            //alert("You can't start a call because you already have one open!");
            evento('Conexión Peer', 'Administrador - Videollamada', { peerConnection: myPeerConnection }, true, objetoConfiguracion);
        } else {
            createPeerConnection(servers);
            const cameraId = getConnectedDevices('videoinput');
            const mediaStream = await openCamera(cameraId);
            if (mediaStream) {
                if (loading) setLoading(false);
                setStream(mediaStream);
                myVideo.current.srcObject = mediaStream;
                mediaStream.getTracks().forEach(track => myPeerConnection.addTrack(track, mediaStream));
            }
            /* navigator.mediaDevices.getUserMedia(mediaConstraints).then((localStream) => {
                setStream(localStream)
                myVideo.current.srcObject = localStream
                localStream.getTracks().forEach(track => myPeerConnection.addTrack(track, localStream));
                window.parent.postMessage('stream', '*')
    
            }).catch(handleGetUserMediaError); */
        }
    }

    const createPeerConnection = (servers) => {
        //console.log("iceServers", servers);
        let turnServers = [];
        for (let index = 1; index < servers.urls.length; index++) {
            turnServers.push(servers.urls[index])
        }
        //console.log("Setting up a connection...");
        myPeerConnection = new RTCPeerConnection(
            {
                iceServers: [{ urls: [servers.urls[0]] }, {
                    username: servers.username,
                    credential: servers.credential,
                    urls: turnServers
                }]
            }
        )
        evento('Nueva conexión Peer', 'Administrador - Videollamada', {
            peerConnection: {
                iceServers: [{ urls: [servers.urls[0]] }, {
                    username: servers.username,
                    credential: servers.credential,
                    urls: turnServers
                }]
            }
        }, true, objetoConfiguracion);
        myPeerConnection.onicecandidate = handleICECandidateEvent;
        myPeerConnection.ontrack = handleTrackEvent;
        myPeerConnection.onnegotiationneeded = handleNegotiationNeededEvent;
        myPeerConnection.onremovetrack = handleRemoveTrackEvent;
        myPeerConnection.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
        myPeerConnection.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
        myPeerConnection.onsignalingstatechange = handleSignalingStateChangeEvent;
    }

    const handleICECandidateEvent = (event) => {
        if (event.candidate) {
            evento('Manejando evento ICE candidate', 'Administrador - Videollamada', event.candidate, true, objetoConfiguracion);
            //console.log("eventIceCandidate",event);
            sendToServer({
                payload: {
                    sdp: event.candidate.candidate,
                    sdpMid: event.candidate.sdpMid,
                    sdpMLineIndex: event.candidate.sdpMLineIndex
                },
                type: "IceCandidate"
            });
        }
    }

    const handleTrackEvent = (event) => {
        //console.log('TRACK_EVENT', event);
        evento('Manejando evento Track', 'Administrador - Videollamada', { event: event }, true, objetoConfiguracion);
        setNewStream(true);
        if (userVideo.current) {
            userVideo.current.srcObject = event.streams[0];
        }
    }

    const handleNegotiationNeededEvent = async () => {
        //console.log("*** Negotiation needed");
        try {
            //console.log("---> Creating offer")
            const offer = await myPeerConnection.createOffer();
            evento('Crear oferta', 'Administrador - Videollamada', { offer: offer }, true, objetoConfiguracion);
            if (myPeerConnection.signalingState !== "stable") {
                //console.log("     -- The connection isn't stable yet; postponing...")
                evento('Estado de señal', 'Administrador - Videollamada', { signalingState: myPeerConnection.signalingState }, true, objetoConfiguracion);
                return;
            }

            //console.log("---> Setting local description to the offer");
            await myPeerConnection.setLocalDescription(offer);

            //console.log("---> Sending the offer to the remote peer");
            sendToServer({
                payload: myPeerConnection.localDescription,
                type: "SessionDescription",
            });
        } catch (error) {
            //console.log("*** The following error occurred while handling the negotiationneeded event:");
            evento('Manejando evento Negotiation Needed', 'Administrador - Error', { error: error }, true, objetoConfiguracion);
        };
    }

    const handleRemoveTrackEvent = (event) => {
        evento('Manejando evento Remove Track', 'Administrador - Videollamada', event, true, objetoConfiguracion);
        let tagVideo = userVideo.current;
        if (tagVideo) {
            let stream = tagVideo.srcObject;
            let trackList = stream.getTracks();
            if (trackList.length === 0) {
                closeVideoCall(true);
            }
        }
    }

    const handleICEConnectionStateChangeEvent = (event) => {
        switch (myPeerConnection.iceConnectionState) {
            case "closed":
                evento('ICE connection state change event', 'Administrador - Closed', { iceConnectionState: myPeerConnection.iceConnectionState, event: event }, true, objetoConfiguracion);
                break;
            case "failed":
                evento('ICE connection state change event', 'Administrador - Failed', { iceConnectionState: myPeerConnection.iceConnectionState, event: event }, true, objetoConfiguracion);
                break;
            case "disconnected":
                evento('ICE connection state change event', 'Administrador - Disconnected', { iceConnectionState: myPeerConnection.iceConnectionState, event: event }, true, objetoConfiguracion);
                closeVideoCall(true);
                break;
            default:
                evento('ICE connection state change event', 'Administrador - Videollamada', { iceConnectionState: myPeerConnection.iceConnectionState, event: event }, true, objetoConfiguracion);
                break;
        }
    }

    const handleICEGatheringStateChangeEvent = (event) => {
        evento('ICE gathering state change event', 'Administrador - Videollamada', { event: event }, true, objetoConfiguracion);
        // Our sample just logs information to console here,
        // but you can do whatever you need.
    }

    const handleSignalingStateChangeEvent = (event) => {
        //console.log("*** WebRTC signaling state changed to: " + myPeerConnection.signalingState);
        evento('Manejando evento Signaling state change', 'Administrador - Videollamda', { event: event }, true, objetoConfiguracion);
        switch (myPeerConnection.signalingState) {
            case "closed":
                closeVideoCall();
                break;
            default:
                break;
        }
    }

    const sendToServer = (msg) => {
        //var msgJSON = JSON.stringify(msg);
        let msgJSON = Buffer.from(JSON.stringify(msg));
        evento('Enviar mensaje al servidor', 'Administrador - Videollamada', { message: msgJSON }, true, objetoConfiguracion);
        socket.send(msgJSON);
    }

    const closeVideoCall = (reconnect = false) => {
        //console.log("colgando");
        let remoteVideo = userVideo.current;
        let localVideo = myVideo.current;
        //console.log(" myPeerConnection. ", myPeerConnection);
        if (myPeerConnection) {
            evento('Colgando llamada', 'Administrador - Videollamada', { peerConnection: myPeerConnection }, true, objetoConfiguracion);
            //console.log("qutando myPeerConnection ");
            myPeerConnection.ontrack = null;
            myPeerConnection.onremovetrack = null;
            myPeerConnection.onremovestream = null;
            myPeerConnection.onicecandidate = null;
            myPeerConnection.oniceconnectionstatechange = null;
            myPeerConnection.onsignalingstatechange = null;
            myPeerConnection.onicegatheringstatechange = null;
            myPeerConnection.onnegotiationneeded = null;
            if (remoteVideo && remoteVideo.srcObject) {
                remoteVideo.srcObject.getTracks().forEach(track => track.stop());
                remoteVideo.removeAttribute("src");
                remoteVideo.removeAttribute("srcObject");
                setNewStream(false);
            }
            if (localVideo && localVideo.srcObject) {
                localVideo.srcObject.getTracks().forEach(track => track.stop());
                localVideo.removeAttribute("src");
                setStream(null);
            }
            myPeerConnection.close();
            myPeerConnection = null;
        }

        if (reconnect) {
            M.toast({ html: 'Intentando reconectar la videollamada' });
            setLoading(true);
            asignarTurno();
        }
        //document.getElementById("hangup-button").disabled = true;
        //targetUsername = null;
    }

    const handleGetUserMediaError = (error) => {
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
            //required track is missing 
            evento('Videollamada', 'Administrador - User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true, objetoConfiguracion);
        } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
            //webcam or mic are already in use 
            evento('Videollamada', 'Administrador - User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true, objetoConfiguracion);
        } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
            //constraints can not be satisfied by avb. devices 
            evento('Videollamada', 'Administrador - User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true, objetoConfiguracion);
        } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
            //permission denied in browser 
            evento('Videollamada', 'Administrador - User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true, objetoConfiguracion);
        } else if (error.name === "TypeError" || error.name === "TypeError") {
            //empty constraints object 
            evento('Videollamada', 'Administrador - User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true, objetoConfiguracion);
        } else {
            //other errors 
            evento('Videollamada', 'Administrador - User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true, objetoConfiguracion);
        }
        closeVideoCall();
    }

    const muteAudio = () => {
        setIsAudioEnable(!isAudioEnable);
        if (myVideo.current) myVideo.current.srcObject.getAudioTracks().forEach(track => {
            track.enabled = !track.enabled
        });
    }

    const muteVideo = () => {
        setIsVideoEnable(!isVideoEnable);
        if (myVideo.current) myVideo.current.srcObject.getVideoTracks().forEach(track => {
            track.enabled = !track.enabled
        });
    }

    const getConnectedDevices = async (type, label = '') => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === type && (label && (device.label === label)));
    }

    async function openCamera(cameraId) {
        const constraints = {
            'audio': {
                'echoCancellation': true,
            },
            'video': {
                'deviceId': cameraId,
                'width': 320,
                'height': 240,
                'frameRate': {
                    'ideal': 8,
                    'max': 12,
                },
            },
        }
        return await navigator.mediaDevices.getUserMedia(constraints).catch(handleGetUserMediaError);
    }

    const revisarTurnoEnLlamada = () => {
        const idCall = location.state?.objetoTurno.idCall;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        revisarEstadoTurno(idCall, transaccion).then(({ status, data }) => {
            if (status === 200) {
                evento('Revisar turno', 'Administrador - Success', mapearRespuesta(status, data), true, objetoConfiguracion);
                if (data.payload[0].idStatusTurno.id === "EN_LLAMADA") {
                    setTimeout(() => {
                        startCall();
                    }, 500);
                } else {
                    setTimeout(() => {
                        revisarTurnoEnLlamada();
                    }, 3000);
                }
            }
        }).catch((error) => {
            let errorMapeado = mapearError(error);
            evento('Revisar turno', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
        })
    }

    const asignarTurno = (reconectar) => {
        const idCall = location.state?.objetoTurno.idCall;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        asignarLLamadaAgente(idCall, transaccion).then(({ data, status }) => {
            if (status === 200) {
                if (reconectar) {
                    sendToServer({
                        payload: {
                            reconectar: true,
                        },
                        type: "Connection"
                    });
                }
                setNombreCliente(`${data.payload.nombre} ${data.payload.apellidoPaterno} ${data.payload.apellidoMaterno}`)
                evento('Asignar turno', 'Administrador - Success', mapearRespuesta(status, data), true, objetoConfiguracion);
                revisarTurnoEnLlamada();
            }
        }).catch(async (error) => {
            let errorMapeado = mapearError(error);
            evento('Asignar turno', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
            if (errorMapeado.objetoError.tipo === 'RESPONSE' && errorMapeado.objetoError.status === 401) {
                let rtoken = localStorage.getItem("token_refresh");
                await renovarToken(rtoken);
                asignarTurno();
            }
            //console.error(err);
        })

    }

    const colgarLLamada = () => {
        const idCall = location.state?.objetoTurno.idCall;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        if (veredicto) {
            closeVideoCall();
            setLoading(true);
            enviarVeredictoTurno(idCall, transaccion, veredicto).then(response => {
                if (response.status === 200) {
                    evento('Enviar veredicto', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
                    // mediaRecordStream.stop();
                    // mediaStream.getVideoTracks()[0].stop();
                    // mediaStream.getAudioTracks()[0].stop();
                    // voiceStream.getAudioTracks()[0].stop();
                    finalizarLLamadaAgente(idCall, transaccion).then((response) => {
                        if (response.status === 200) {
                            evento('Finalizar llamada', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
                            setTimeout(() => {
                                history.replace('/home');
                            }, 300);
                        }
                    }).catch((error) => {
                        //console.error(err);
                        let errorMapeado = mapearError(error);
                        evento('Finalizar llamada', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
                    })
                }
            }).catch(error => {
                //console.error(error);
                let errorMapeado = mapearError(error);
                evento('Enviar veredicto', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
            })
        } else {
            M.toast({ html: 'Debes seleccionar un veredicto para terminar la llamada' });
        }

    }

    const obtenerDocumentosClienteZip = () => {
        setLoading(true);
        const uuidUser = location.state?.objetoTurno.clienteUuid;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        getDocumentsZipClient(uuidUser, transaccion).then((response) => {
            evento('Archivo ZIP cliente', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
            let blob = new Blob([response.data], { type: 'application/zip' });
            let fileName = "documentos-" + uuidUser.split("-")[0] + ".zip"
            saveAs(blob, fileName);
            setLoading(false);
        }).catch((error) => {
            let errorMapeado = mapearError(error);
            evento('Archivo ZIP cliente', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
            M.toast({ html: 'No hay archivos para descargar' });
            setLoading(false);
        })
    }

    const obtenerDocumentosCliente = (idUser, transaccion) => {
        getDocumentsClient(idUser, transaccion).then((response) => {
            evento('Documentos cliente', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
            let documentosArray = [];
            response.data.payload.links.forEach(documento => {
                if (documento.file !== 'selfie' && documento.file !== 'pdfautorizacion' && documento.file !== 'fingerprint_right' && documento.file !== 'fingerprint_left' && documento.file !== 'ine' && documento.file !== 'renapo') {
                    documentosArray.push(documento);
                }
            });
            setDocumentos(documentosArray);
        }).catch((error) => {
            //console.error(error);
            let errorMapeado = mapearError(error);
            evento('Documentos cliente', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
        })
    }

    const obtenerTooltip = (nombreDocumento) => {
        switch (nombreDocumento) {
            case "videoaceptacion":
                return "Mostrar video de aceptación";
            case "domicilio":
                return "Mostrar c. domicilio";
            case "identificacion_back":
                return "Mostrar identificación";
            case "identificacion_front":
                return "Mostrar identificación";
            case "fingerprint_right":
                return "Mostrar huellas";
            case "fingerprint_left":
                return "Mostrar huellas";
            case "capture":
                return "Mostrar rostro";
            default:
                return "";
        }
    }

    const obtenerIcono = (nombreDocumento) => {

        switch (nombreDocumento) {
            case "videoaceptacion":
                return (<span><i className="material-icons left">record_voice_over</i>ACEPTACIÓN</span>);
            case "domicilio":
                return (<span><i className="material-icons left">text_snippet</i>C. DOMICILIO</span>);
            case "identificacion_back":
                return (<span><i className="material-icons left">payment</i>REVERSO</span>);
            case "identificacion_front":
                return (<span><i className="material-icons left">payment</i>FRENTE</span>);
            case "fingerprint_right":
                return (<span><i className="material-icons left">fingerprint</i>DERECHA</span>);
            case "fingerprint_left":
                return (<span><i className="material-icons left">fingerprint</i>IZQUIERDA</span>);
            case "capture":
                return (<span><i className="material-icons left">face</i>ROSTRO</span>);
            default:
                return "";
        }
    }

    const mostrarDocumento = (link) => {
        window.open(link);
    }

    const tomarCapturaPantalla = () => {
        const video = userVideo.current;
        const canvas = document.createElement('canvas');
        canvas.setAttribute('hidden', '');
        canvas.width = video.videoWidth * 1.5
        canvas.height = video.videoHeight * 1.5

        setTimeout(() => {
            const contextCanvas = canvas.getContext('2d');
            contextCanvas.drawImage(
                video, 0, 0,
                video.videoWidth * 1.5,
                video.videoHeight * 1.5)
            setBase64Image(canvas.toDataURL());
        }, 1350)
    }

    const verCapturaPantalla = () => {
        setSrc(base64Image);
        setIsModalOpen(true);
    }

    const enviarCaptura = () => {
        setLoading(true);
        const idCall = location.state?.objetoTurno.idCall;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        const uuidUser = location.state?.objetoTurno.clienteUuid;
        compararRostroCliente(idCall, uuidUser, transaccion, base64Image).then(response => {
            //console.log(response);
            evento('Comparación rostro cliente', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
            if (response.status === 200 && response.data.payload.match) {
                setIsModalOpen(false);
                setBase64Image('');
                setLoading(false)
            } else {
                M.toast({ html: 'No se encontraron condidencias, por favor verifique la captura.' });
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
            //console.error(error);
            M.toast({ html: 'No se ha logrado detectar un rostro, favor de ajustar la ilumación, el enfoque e intente sobre un fondo claro, libre de objetos.' });
            let errorMapeado = mapearError(error);
            evento('Comparación rostro cliente', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
        })
    }

    const eliminarCaptura = () => {
        setBase64Image('');
    }

    const obtenerValidacionesCliente = () => {
        const idCall = location.state?.objetoTurno.idCall;
        const transaccion = location.state?.objetoTurno.clienteTransaccion;
        obtenerValidacionesTurno(idCall, transaccion).then((response) => {
            if (response.status === 200) {
                evento('Obtener validaciones turno', 'Administrador - Success', mapearRespuesta(response.status, response.data), true, objetoConfiguracion);
                let cliente = response.data.payload[0];
                obtenerColorValidacionScore(cliente.idStatusScore);
                obtenerColorValidacionIne(cliente.ine);
                obtenerColorValidacionCaptura(cliente.captura);
                if(!validacionRenapo){
                    obtenerColorValidacionRenapo(cliente.renapo);
                    setValidacionRenapo(cliente.renapo);
                }
                setValidacionIne(cliente.ine);
            }
        }).catch(error => {
            //console.error(error);
            let errorMapeado = mapearError(error);
            evento('Obtener validaciones turno', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
        })
    }

    const obtenerColorValidacionScore = (score) => {
        switch (score) {
            case "GREEN":
                setScore({
                    text: "STATUS",
                    color: "green"
                });
                break;

            case "YELLOW":
                setScore({
                    text: "STATUS",
                    color: "amber"
                });
                break;
            case "RED":
                setScore({
                    text: "STATUS",
                    color: "red"
                });
                break;
            case null:
                setScore({
                    text: "SIN RESULTADO",
                    color: "grey"
                });
                break;
            default:
                break;
        }
    }

    const obtenerColorValidacionIne = (ine) => {
        switch (ine) {
            case "VALIDADO":
                setIne({
                    text: "INE",
                    color: "green"
                });
                break;

            case "INVALIDO":
                setIne({
                    text: "INE",
                    color: "red"
                });
                break;
            case "ERROR":
                setIne({
                    text: "INE",
                    color: "red"
                });
                break;
            case "PENDIENTE":
                setIne({
                    text: "INE",
                    color: "amber"
                });
                break;
            case null:
                setIne({
                    text: "SIN RESULTADO",
                    color: "grey"
                });
                break;
            default:
                break;
        }
    }

    const obtenerColorValidacionRenapo = (renapo) => {
        let validacionRenapo = renapo ? renapo.trim() : renapo;
        switch (validacionRenapo) {
            case "VALIDADO":
                setRenapo({
                    text: "RENAPO",
                    color: "green"
                });
                break;

            case "INVALIDO":
                setRenapo({
                    text: "RENAPO",
                    color: "red"
                });
                break;
            case "ERROR":
                setRenapo({
                    text: "RENAPO",
                    color: "red"
                });
                break;
            case null:
                setRenapo({
                    text: "SIN RESULTADO",
                    color: "grey"
                });
                break;
            default:
                break;
        }
    }

    const obtenerColorValidacionCaptura = (captura) => {
        switch (captura) {
            case "VALIDADO":
                setCaptura({
                    text: "CAPTURA",
                    color: "green"
                });
                break;

            case "INVALIDO":
                setCaptura({
                    text: "CAPTURA",
                    color: "red"
                });
                break;
            case "ERROR":
                setCaptura({
                    text: "CAPTURA",
                    color: "red"
                });
                break;
            case null:
                setCaptura({
                    text: "SIN RESULTADO",
                    color: "grey"
                });
                break;
            default:
                break;
        }
    }

    const validarRenapo = () => {
        setLoading(true);
        if (validacionRenapo) {
            setLoading(false);
        } else {
            const idCall = location.state?.objetoTurno.idCall;
            const transaccion = location.state?.objetoTurno.clienteTransaccion;
            const uuidUser = location.state?.objetoTurno.clienteUuid;
            obtenerValidacionRenapoTurno(uuidUser, idCall, transaccion).then(({ status, data, data: { payload: { item } } }) => {
                evento('Validación renapo', 'Administrador - Success', mapearRespuesta(status, data), true, objetoConfiguracion);
                let estatus = item[0].status;
                if (item.length > 0) {
                    obtenerColorValidacionRenapo(estatus);
                    setValidacionRenapo(estatus);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch(error => {
                setLoading(false)
                //console.error(error);
                let errorMapeado = mapearError(error);
                evento('Validación renapo', errorMapeado.tipoError, errorMapeado.objetoError, false, objetoConfiguracion);
            })
        }
    }


    return (
        <>
            <div className="main_gradient container-flex">
                <HeaderPage></HeaderPage>
                <div id='videocall-container'>
                    <div className="app">
                        <div className='videos videos-grid'>
                            <div className='video_container mirrored' style={{ flexBasis: newStream ? '50%' : '100%' }}>
                                {stream && <>
                                    <video id="local_video" playsInline muted ref={myVideo} autoPlay />
                                    <div className='video-footer'>
                                        <input className="nickname browser-default" type="text" value="Tú" readOnly />
                                    </div>
                                    <div className="side align left children" style={{ alignItems: 'flex-end', zIndex: 2 }}>
                                        <div className="toolbar active">
                                            <a className={['button mute-audio', (!isAudioEnable) ? 'on' : ''].join(' ')} onClick={() => muteAudio()}>
                                                <span className={['icon material-icons', (isAudioEnable) ? 'icon-mic' : 'icon-mic_off'].join(' ')}>
                                                </span>
                                                <span className="tooltip">Encender/apagar micrófono</span>
                                            </a>
                                            <a className={['button mute-video', (!isVideoEnable) ? 'on' : ''].join(' ')} onClick={() => muteVideo()}>
                                                <span className={['icon material-icons', (isVideoEnable) ? 'icon-videocam' : 'icon-videocam_off'].join(' ')}>
                                                </span>
                                                <span className="tooltip">Encender/apagar camara</span></a>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            {newStream && <div className='video_container' style={{ flexBasis: '50%' }}>
                                <video id="received_video" playsInline ref={userVideo} autoPlay />
                                <div className='video-footer'>
                                    <input className="nickname browser-default" type="text" defaultValue={nombreCliente} readOnly />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className='div-flex'>
                    <div className="agent_actions">
                        <div className="foot_call_view_b">
                            <Button
                                className="btn-floating btn-large waves-effect waves-light red agent-btn-margin"
                                node="button"
                                tooltip="Para finalizar debes marcar un veredicto"
                                waves="light"
                                onClick={e => colgarLLamada()}
                            >
                                <i className="material-icons">call_end</i>
                            </Button>
                            <Button
                                className="btn-floating btn-large waves-effect waves-light red agent-btn-margin"
                                node="button"
                                tooltip="Captura de pantalla"
                                waves="light"
                                onClick={e => tomarCapturaPantalla()}
                            >
                                <i className="material-icons blue">add_a_photo</i>
                            </Button>
                            <div className="col s12"></div>
                        </div>
                    </div>

                    <div className="agent_info grey lighten-2">


                        <div className="col s12">
                            <Tabs className="tabs">
                                <Tab
                                    options={{
                                        duration: 300,
                                        onShow: null,
                                        responsiveThreshold: Infinity,
                                        swipeable: false
                                    }}
                                    title="Validaciones"
                                >
                                    <div id="test1" className="col s12">

                                        <div className="info_btns">

                                            <a className={"waves-effect waves-light btn " + score.color + " accent-4"} style={{ cursor: 'default' }}><i className="material-icons left">traffic</i>{score.text}</a><span> </span>

                                            {/* <a className={"waves-effect waves-light btn " + ine.color + " accent-4" + ((validacionIne === '' || validacionIne === null) ? "" : " default-cursor")} onClick={e => validarIne()}><i className="material-icons left">fingerprint</i>{ine.text}</a><span> </span> */}

                                            <a className={"waves-effect waves-light btn " + renapo.color + " accent-4" + ((validacionRenapo === '' || validacionRenapo === null) ? "" : " default-cursor")} onClick={e => validarRenapo()}><i className="material-icons left">folder_shared</i>{renapo.text}</a><span> </span>

                                            <a className={"waves-effect waves-light btn " + captura.color + " accent-4"} style={{ cursor: 'default' }}><i className="material-icons left">repeat</i>{captura.text}</a><span> </span>

                                            <a className="waves-effect waves-light btn grey darken-3" onClick={e => { obtenerValidacionesCliente() }}><i className="material-icons">refresh</i></a>

                                        </div>

                                    </div>
                                </Tab>
                                <Tab
                                    options={{
                                        duration: 300,
                                        onShow: null,
                                        responsiveThreshold: Infinity,
                                        swipeable: false
                                    }}
                                    title="Documentos"
                                >
                                    <div id="test2" className="col s12">
                                        <div className="info_btns">

                                            {(documentos.length > 0) ? documentos.map((objeto, index) => (
                                                <span key={index + 1}>
                                                    <Button
                                                        className="btn grey darken-3 btn-margin"
                                                        node="button"
                                                        tooltip={obtenerTooltip(objeto.file)}
                                                        waves="light"
                                                        onClick={e => mostrarDocumento(objeto.link.payload.link)}
                                                        tooltipOptions={{
                                                            position: 'top'
                                                        }}
                                                    >
                                                        {obtenerIcono(objeto.file)}
                                                    </Button>
                                                </span>)) :
                                                <b className="grey-text text-darken-4">No hay documentos para mostrar</b>
                                            }
                                            <Button
                                                className="btn grey darken-3 btn-margin"
                                                node="button"
                                                tooltip="Descargar documentos"
                                                waves="light"
                                                onClick={e => obtenerDocumentosClienteZip()}
                                                tooltipOptions={{
                                                    position: 'top'
                                                }}
                                            >
                                                <span><i className="material-icons left">download</i>DESCARGAR</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    options={{
                                        duration: 300,
                                        onShow: null,
                                        responsiveThreshold: Infinity,
                                        swipeable: false
                                    }}
                                    title="Capturas"
                                >
                                    <div id="test3" className="col s12">
                                        <div className="info_btns">
                                            {base64Image ?
                                                <span>
                                                    <a className="waves-effect waves-light btn grey darken-3 modal-trigger" href="#Modal_captura" onClick={e => { verCapturaPantalla() }}>VER CAPTURA</a><a className="waves-effect waves-light btn red accent-4" onClick={e => { eliminarCaptura() }}>X</a><span> </span>
                                                </span> :
                                                <b className="grey-text text-darken-4">No hay capturas para mostrar</b>
                                            }

                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    options={{
                                        duration: 300,
                                        onShow: null,
                                        responsiveThreshold: Infinity,
                                        swipeable: false
                                    }}
                                    title="Veredicto"
                                >
                                    <div id="test4" className="col s12">
                                        <div className="info_btns">

                                            <form className="row" action="#">
                                                <p className="col s3">
                                                    <label>
                                                        <input id="aprobado" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("APROBADO")} />
                                                        <span><b className="grey-text text-darken-4">Aprobado</b></span>
                                                    </label>
                                                </p>
                                                <p className="col s3">
                                                    <label>
                                                        <input id="no_aprobado" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("NO_APROBADO")} />
                                                        <span><b className="grey-text text-darken-4">No aprobado</b></span>
                                                    </label>
                                                </p>
                                                <p className="col s3">
                                                    <label>
                                                        <input id="escalar" className="with-gap" name="group1" type="radio" onClick={e => setVeredicto("ESCALAR")} />
                                                        <span><b className="grey-text text-darken-4">Escalar</b></span>
                                                    </label>
                                                </p>
                                            </form>


                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div>

            </div>

            <Modal
                actions={[
                    <span>
                        <button className="modal-close waves-effect waves-green btn-flat" type="button">Cancelar</button>
                        <button className="waves-effect waves-green btn" type="button" onClick={() => { enviarCaptura() }}>Enviar</button>
                    </span>
                ]}
                bottomSheet={false}
                fixedFooter
                header="Mostrar captura"
                id="Modal_captura"
                open={isModalOpen}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: '4%'
                }}
                root={document.body}
            >
                {/*<ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          onChange={crop => setCrop(crop)}
          onImageLoaded={onImageLoaded}
        />*/}
                <div className="App">
                    <img src={base64Image} width="320" height="410"></img>
                </div>
                <Spinner state={loading}></Spinner>
            </Modal>

            <Spinner state={loading}></Spinner>

            <LogoutPopup></LogoutPopup>

        </>
    )
}

export default Videollamada
