import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import { SketchPicker } from 'react-color';
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { obtenerListaTurnos } from '../services/api.js';
import { Table, Select, DatePicker, Modal } from "react-materialize";
import { TableHeader, Pagination, Search } from "../components/DataTable";
import Spinner from '../components/Spinner';

const Seguimientos = () => {


  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [hostMinio, setHostMinio] = useState('proddicio');
  const [search, setSearch] = useState("");
  const [dataOtorgante, setDataOtorgante] = useState([]);
  const [dataClientes, setDataClientes] = useState([])
  const [loading, setLoading] = useState(false);
  const [rol, setRol] = useState("");
  const [currentClientId, setCurrentClientId] = useState('');
  const [currentTransId, setCurrentTransId] = useState('');
  const [currentOtorganteId, setCurrentOtorganteId] = useState('');
  const [nombreCliente, setNombreCliente] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchOtorgante, setSearchOtorgante] = useState("");

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const headers = [
    //{ name: "ID", field: "clienteUuid", sortable: false },
    { name: "Contacto", field: "nombreCompleto", sortable: false },
    { name: "Teléfono", field: "telefono", sortable: false },
    { name: "Duración", field: "duracion", sortable: false },
    //{ name: "Estatus (Llamada)", field: "status", sortable: false },
    { name: "INE", field: "ine", sortable: false },
    { name: "RENAPO", field: "renapo", sortable: false },
    { name: "Veredicto", field: "veredicto", sortable: false },
    { name: "Score", field: "score", sortable: false },
    { name: "Acciones", field: "action", sortable: false }
  ];

  const dataListaClientes = useMemo(() => {
    let computedComments = dataClientes;
    setTotalItems(computedComments.length);
    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.clienteUuid.includes(search.toLowerCase().trim())
          || (comment.nombre !== null && comment.nombre.toLowerCase().includes(search.toLowerCase()))
          || comment.idOtorgante.id.toLowerCase().includes(search.toLowerCase().trim())
          || comment.idStatusTurno.id.toLowerCase().includes(search.toLowerCase().trim())
          || comment.clienteTransaccion.toLowerCase().includes(search.toLowerCase().trim())
          || (comment.idStatusScore !== null && comment.idStatusScore.toLowerCase().includes(search.toLowerCase().trim()))
          || (comment.apellidoMaterno !== null && comment.apellidoMaterno.toLowerCase().includes(search.toLowerCase()))
          || (comment.apellidoPaterno !== null && comment.apellidoPaterno.toLowerCase().includes(search.toLowerCase()))
          || (comment.nombreCompleto !== null && comment.nombreCompleto.toLowerCase().includes(search.toLowerCase()))
          || (comment.ine !== null && comment.ine.toLowerCase().includes(search.toLowerCase()))
          || (comment.renapo !== null && comment.renapo.toLowerCase().includes(search.toLowerCase()))
          || (comment.veredicto !== null && comment.veredicto.toLowerCase().replace("_", " ").includes(search.toLowerCase().trim()))
      );
    }

    if (searchOtorgante) {
      computedComments = computedComments.filter(
        comment =>
          comment.idOtorgante.id.toLowerCase().includes(searchOtorgante.toLowerCase().trim())
      );
    }
    setTotalItems(computedComments.length);
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [dataClientes, currentPage, sorting, search, searchOtorgante]);

  useEffect(() => {
    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }
    let host = window.location.hostname;
    if (host.includes("adminotorgante.devdicio")) {
      setHostMinio("devdicio");
    } else if (host.includes("adminotorgante.dev.devdicio")) {
      setHostMinio("dev.devdicio");
    }

    let lista = localStorage.getItem("dataOtorgante");
    if (lista !== null) {
      let options = []
      let final = JSON.parse(lista)
      for (var i = 0; i < final.length; i++) {
        var obj = final[i];
        var nuevo = new Object();
        nuevo.id = obj.id;
        nuevo.nombre = obj.nombre;
        options.push(nuevo)
      }
      setDataOtorgante(options)
    }

    let rolUser = localStorage.getItem("roles");
    if (rolUser !== null) {
      setRol(rolUser)
    }

    obtenerListaClientes();
    //window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    localStorage.setItem("listaAgentes", JSON.stringify(dataClientes));
    setDataClientes(dataClientes);
  }, [dataClientes])

  const obtenerListaClientes = async () => {
    //console.log("obtener lista");
    setLoading(true);
    obtenerListaTurnos().then((res) => {
      if (res.status === 200) {
        let data = [];
        let dataResponse = res.data.payload.sort(function (a, b) { return b.id - a.id });
        dataResponse.forEach(objeto => {
          if (objeto.idStatusTurno.id === 'INTERRUMPIDO') {
            data.push(objeto);
          }
        });
        setDataClientes(data);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("algun error:", err);
      if (err.toString().includes("401")) {
        cerrar();
      }
    })
    setTimeout(() => {
      setLoading(false)
    }, 4000);

  }

  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const date_sort = (a, b) => {
    return new Date(b.fechaFinTurno).getTime() - new Date(a.fechaFinTurno).getTime();
  }

  const busquedaFechas = () => {
    setLoading(true);
    let init = document.getElementById("desde").value;
    let end = document.getElementById("hasta").value;
    let init2 = new Date(init);
    let end2 = new Date(end);
    let dayInMillis = 24 * 3600000;
    let limiteMenor = Math.floor(init2.getTime() / dayInMillis);
    let limiteMayor = Math.floor(end2.getTime() / dayInMillis);

    if (init !== "" && end !== "" && init2.toString() !== "Invalid Date" && end2.toString() !== "Invalid Date") {
      if (init2 <= end2) {
        obtenerListaTurnos().then((response) => {
          if (response.status === 200) {
            if (response.data.payload.length > 0) {
              let data = [];
              let dataResponse = response.data.payload;
              dataResponse.forEach(objeto => {
                if (objeto.idStatusTurno.id === 'TERMINADO') {
                  data.push(objeto);
                }
              });
              let y = data.sort(date_sort);
              let newArray = [];
              var x = y.map(item => {
                var temp = Object.assign({}, item);
                var fecha = new Date(temp.fechaFinTurno);
                let actual = Math.floor(fecha.getTime() / dayInMillis);
                if ((actual <= limiteMayor) && (actual >= limiteMenor)) {
                  newArray.push(temp)
                  return temp;
                }
              });

              setDataClientes(newArray);
              setCurrentPage(1);
              M.toast({ html: 'Datos actualizados' });
            } else {
              setDataClientes([])
            }
          }
          setLoading(false)
        }).catch((error) => {
          setLoading(false);
          console.log("algun error" + error);
          if (error.toString().includes("401")) {
            M.toast({ html: 'Tiempo de sesión terminado' });
            setTimeout(() => {
              history.push('/index');
            }, 200);
          }
        })
      } else {
        M.toast({ html: 'El rango de fechas no es valido' });
        setLoading(false);
      }
    } else {
      //M.toast({ html: 'Seleciona un rango de fechas' });
      setLoading(false);
    }
  }

  const actualizar = () => {
    obtenerListaClientes();
    document.getElementById("desde").value = "";
    document.getElementById("hasta").value = "";
    setSearch('');
    setSearchOtorgante('');
  }

  const verificarStatus = (status, tipo) => {
    switch (tipo) {
      case "veredicto":
        switch (status) {
          case "APROBADO":
            return "green"
          case "ESCALAR":
            return "amber"
          case "NO_APROBADO":
            return "red"
          default:
            break;
        }
        break;
      case "score":
        switch (status) {
          case "GREEN":
            return "green"
          case "YELLOW":
            return "amber"
          case "RED":
            return "red"
          default:
            break;
        }
        break;
      case "ine":
        switch (status) {
          case "VALIDADO":
            return "green"
          case "ERROR":
            return "gray"
          case "INVALIDO":
            return "red"
          default:
            break;
        }
        break;
      case "renapo":
        switch (status) {
          case "VALIDADO":
            return "green"
          case "ERROR":
            return "gray"
          case "INVALIDO":
            return "red"
          default:
            break;
        }
      default:
        break;
    }
  }

  const verificarTexto = (status, tipo) => {
    switch (tipo) {
      case "score":
        switch (status) {
          case "GREEN":
            return "EXITOSO"
          case "YELLOW":
            return "VALIDAR"
          case "RED":
            return "NO EXITOSO"
          default:
            return "";
        }
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">

        <br /><br />
        <div className="col s12 m11 z-depth-3 mzauto extra_pdd">

          <div className="col s12 jumbotron clear_float">
            <h4>Seguimentos</h4>
            <p className="lead">Ve el listado de procesos interrumpidos.</p>
            <hr className="my-4" />
            {(rol !== "ROLE_SUPER_ADMINISTRADOR") ? <a className="waves-effect waves-light btn gray go_call_btn" onClick={e => actualizar()}>actualizar</a> : ""}
          </div>
          <br></br>
          <div className="row">
            {(rol === "ROLE_SUPER_ADMINISTRADOR") ?
              <div>
                <div className="col s12 m6 select">
                  <Select
                    id="Select-9"
                    multiple={false}
                    onChange={(e) => setSearchOtorgante(e.target.value)}
                    options={{
                      classes: 'forcewidth100',
                      dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                      }
                    }}
                    value=""
                  >
                    <option
                      disabled
                      value=""
                    >
                      Selecciona un otorgante
                                    </option>
                    {dataOtorgante.map((item, index) => <option key={`${item.index}`} value={`${item.id}`}>{item.nombre}</option>)}
                  </Select>
                </div>
                <div className="col s12 m2"></div>
                <div className="col s12 m4">
                  <div className="topMargin">
                    <button type="button" onClick={e => actualizar()} className="btn btn-raised btn-primary" style={{ width: "95%" }}>Actualizar lista</button>
                  </div>
                </div>
              </div>
              : ""}

            <div className="col s12 ">
              <form method="post" action="">
                <div className="datepickerM">
                  <DatePicker
                    id="desde"
                    options={{
                      autoClose: false,
                      container: 'body',
                      defaultDate: null,
                      disableDayFn: null,
                      disableWeekends: false,
                      events: [],
                      firstDay: 0,
                      format: 'yyyy-mm-dd',
                      i18n: {
                        cancel: 'Cancel',
                        clear: 'Clear',
                        done: 'Ok',
                        months: [
                          'Enero',
                          'Febreo',
                          'Marzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre'
                        ],
                        monthsShort: [
                          'Ene',
                          'Feb',
                          'Mar',
                          'Abr',
                          'May',
                          'Jun',
                          'Jul',
                          'Ago',
                          'Sep',
                          'Oct',
                          'Nov',
                          'Dic'
                        ],
                        nextMonth: '›',
                        previousMonth: '‹',
                        weekdays: [
                          'Domingo',
                          'Lunes',
                          'Martes',
                          'Miércoles',
                          'Jueves',
                          'Viernes',
                          'Sábado'
                        ],
                        weekdaysAbbrev: [
                          'D',
                          'L',
                          'M',
                          'M',
                          'J',
                          'V',
                          'S'
                        ],
                        weekdaysShort: [
                          'Dom',
                          'Lun',
                          'Mar',
                          'Mie',
                          'Jue',
                          'Vie',
                          'Sab'
                        ]
                      },
                      isRTL: false,
                      maxDate: null,
                      minDate: null,
                      onClose: e => busquedaFechas(),
                      onDraw: null,
                      onOpen: null,
                      onSelect: null,
                      parse: null,
                      setDefaultDate: false,
                      showClearBtn: false,
                      showDaysInNextAndPreviousMonths: false,
                      showMonthAfterYear: false,
                      yearRange: 10
                    }}
                    label="Filtrar desde"
                  />
                </div>

                <div className="datepickerM">
                  <DatePicker
                    id="hasta"
                    options={{
                      autoClose: false,
                      container: 'body',
                      defaultDate: null,
                      disableDayFn: null,
                      disableWeekends: false,
                      events: [],
                      firstDay: 0,
                      format: 'yyyy-mm-dd',
                      i18n: {
                        cancel: 'Cancel',
                        clear: 'Clear',
                        done: 'Ok',
                        months: [
                          'Enero',
                          'Febreo',
                          'Marzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre'
                        ],
                        monthsShort: [
                          'Ene',
                          'Feb',
                          'Mar',
                          'Abr',
                          'May',
                          'Jun',
                          'Jul',
                          'Ago',
                          'Sep',
                          'Oct',
                          'Nov',
                          'Dic'
                        ],
                        nextMonth: '›',
                        previousMonth: '‹',
                        weekdays: [
                          'Domingo',
                          'Lunes',
                          'Martes',
                          'Miércoles',
                          'Jueves',
                          'Viernes',
                          'Sábado'
                        ],
                        weekdaysAbbrev: [
                          'D',
                          'L',
                          'M',
                          'M',
                          'J',
                          'V',
                          'S'
                        ],
                        weekdaysShort: [
                          'Dom',
                          'Lun',
                          'Mar',
                          'Mie',
                          'Jue',
                          'Vie',
                          'Sab'
                        ]
                      },
                      isRTL: false,
                      maxDate: null,
                      minDate: null,
                      onClose: e => busquedaFechas(),
                      onDraw: null,
                      onOpen: null,
                      onSelect: null,
                      parse: null,
                      setDefaultDate: false,
                      showClearBtn: false,
                      showDaysInNextAndPreviousMonths: false,
                      showMonthAfterYear: false,
                      yearRange: 10
                    }}
                    label="Filtrar hasta"
                  />
                </div>
                <div className="col m2 topMargin">
                  {/* <button type="button" className="waves-effect btn-flat" onClick={e => busquedaFechas()}> <i className="material-icons prefix">search</i></button> */}
                </div>
              </form>
              <div className="col m4 d-flex flex-row-reverse input-field">
                <Search
                  onSearch={value => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>

          </div>
          <Table>
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
            <tbody>
              {(dataListaClientes.length > 0) ? dataListaClientes.map((item, index) => (
                <tr key={item.id}>
                  <td><a href={"mailto:" + item.correo ? item.correo : ""}>{item.nombreCompleto ? item.nombreCompleto : (item.nombre + " " + item.apellidoPaterno + " " + item.apellidoMaterno)}</a></td>
                  <td><a href={"tel:" + item.telefono ? item.telefono : ""}>{item.telefono ? item.telefono : "Sin teléfono"}</a></td>
                  <td>{item.fechaFinTurno ? (Math.round((new Date(item.fechaFinTurno) - new Date(item.fechaTomaTurno)) / 60000)).toString() + " min." : "Sin duración"}</td>
                  <td>{(item.ine) ? <a href="#!" ><span className={"new badge " + verificarStatus(item.ine, "ine") + " accent-4 text-btn"}>{item.ine}</span></a> : <a href="#!" ><span className="new badge gray accent-4 text-btn">SIN RESULTADO</span></a>}</td>
                  <td>{(item.renapo) ? <a href="#!" ><span className={"new badge " + verificarStatus(item.renapo, "renapo") + " accent-4 text-btn"}>{item.renapo}</span></a> : <a href="#!" ><span className="new badge gray accent-4 text-btn">SIN RESULTADO</span></a>}</td>
                  <td>{(item.veredicto) ? <a href="#!" ><span className={"new badge " + verificarStatus(item.veredicto, "veredicto") + " accent-4 text-btn"}>{item.veredicto.replace("_", " ")}</span></a> : <a href="#!" ><span className="new badge gray accent-4 text-btn">SIN VEREDICTO</span></a>}</td>
                  <td>{(item.idStatusScore) ? <a href="#!" ><span className={"new badge " + verificarStatus(item.idStatusScore, "score") + " accent-4 text-btn"}>{verificarTexto(item.idStatusScore, "score")}</span></a> : <a href="#!" ><span className="new badge gray accent-4 text-btn">SIN SCORE</span></a>}</td>
                  <td><button style={{ backgroundColor: "white", border: "none", cursor: "pointer" }} type="button"
                    onClick={e => {
                      item.nombreCompleto ? setNombreCliente(item.nombreCompleto) : setNombreCliente(item.nombre + " " + item.apellidoMaterno + " " + item.apellidoPaterno)
                      setCurrentClientId(item.clienteUuid);
                      setCurrentTransId(item.clienteTransaccion);
                      setCurrentOtorganteId(item.idOtorgante.id);
                      let btn = document.getElementById(`btn-modal-open${index}`)
                      btn.click();
                    }}>
                    <span className="new badge hoverable">DETALLE</span>
                    <a hidden href="#modalDetails" id={`btn-modal-open${index}`} className="modal-trigger" ></a></button>
                  </td>
                </tr>
              )) :
                <tr>
                  <td colSpan="8">
                    Sin resultados.
                                    </td>
                </tr>}
            </tbody>
          </Table>
          <br></br>

          <div className="row">
            <div className="col s12 ">
              <div className="centrarDiv">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>

          </div>

        </div>

      </div>

      <Modal
        actions={[
          <span>
            <a href="#!" className="modal-close waves-effect btn">Cerrar</a>
          </span>
        ]}
        bottomSheet={false}
        fixedFooter={true}
        header={"Cliente: " + nombreCliente}
        id="modalDetails"
        open={isModalOpen}
        root={document.body}
      >
        <div className="row">

          <table className="responsive-table">
            <thead>
              <tr>
                <th>Tipo UID:</th>
                <th>UID:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Cliente
                                </td>
                <td>
                  <b>
                    {currentClientId}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Transacción
                                </td>
                <td>
                  <b>
                    {currentTransId}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Otorgante
                                </td>
                <td>
                  <b>
                    {currentOtorganteId}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>
    </div>
  )
}

export default Seguimientos