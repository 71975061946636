import React, { useState } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { actualizarContrasena, resetUser } from '../services/api.js';


const Restablecer = () => {

  const history = useHistory();
  const [isRecuperar, setIsRecuperar] = useState('false');
  const [passwordR, setPasswordR] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    let user = localStorage.getItem("username")
    if (user === "") {
    }
    let to = localStorage.getItem("token")
    if (to === "") {
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('restablecer');
    if (myParam === "true") {
      setIsRecuperar("true")
    }

    //materializeStylos();
  }, [])


  // const materializeStylos = () => {
  //   var elems = document.querySelectorAll('.modal');
  //   var el = document.querySelectorAll('select');
  //   var elemDP = document.querySelectorAll('.datepicker');
  //   M.Datepicker.init(elemDP, { container: 'body', format:"yyyy-mm-dd", onSelect: function(date) {}});
  //   M.Modal.init(elems, {});
  //   M.FormSelect.init(el, {});
  // }

  const actualizar = () => {
    if (password === passwordR) {
      let iduser = localStorage.getItem("numUser")
      actualizarContrasena(iduser, password).then((response) => {
        if (response.status === 200) {
          M.toast({ html: 'Se ha actualizado contraseña' });
          localStorage.removeItem("numUser");
          setTimeout(() => {
            history.push('/home');
          }, 300);
        } else {
          M.toast({ html: 'Verifica tu conexión a internet' });
        }
      }).catch((err) => {
        console.error(err);
        console.log("algun error" + err);
        M.toast({ html: 'Revisa tu conexión a internet' });
      })
    } else {
      M.toast({ html: 'No coinciden tus contraseñas' });
    }
  }

  const recuperar = () => {
    if (username !== "") {
      resetUser(username).then((response) => {
        if (response.status === 200) {
          if (!response.data.Error) {
            M.toast({ html: 'Se ha mandado un correo con tu nueva contraseña' });
            setTimeout(() => {
              history.push('/index');
            }, 1000);
          } else {
            M.toast({ html: response.data.Error });
          }
        } else {
          M.toast({ html: 'No existe usuario y/o revisa tu conexion a internet' });
        }
      }).catch((err) => {
        console.error(err);
        console.log("algun error" + err);
        M.toast({ html: 'Revisa tu conexión a internet y/o usuario no existe' });
      })
    } else {
      M.toast({ html: 'Debes colocar un correo' });
    }
  }

  useEffect(() => {
  }, [username])

  const revisarContraseña = (value, campo) => {
    let frase = '';
    switch (campo) {
      case 'password':

        if (value.length < 6) {
          frase = "Formato de contraseña no válido, mínimo 6 dígitos"
          document.getElementById("psswd").innerHTML = frase
        } else {
          setPassword(value)
          document.getElementById("psswd").innerHTML = '';
        }

        break;
      case 'passwordR':

        if (value.length < 6) {
          frase = "Formato de contraseña no válido, mínimo 6 dígitos"
          document.getElementById("psswdR").innerHTML = frase
        } else {
          setPasswordR(value)
          document.getElementById("psswdR").innerHTML = '';
        }

        break;
      default:
        break;
    }
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">
        <br />
        <div className="card horizontal col s10 m8 l5 mzauto">
          {
            (isRecuperar === "true") ?
              <div className="card-stacked">
                <div className="card-content">
                  <span className="card-title black-text text-darken-1"><b>Recuperar contraseña</b></span>
                  <small>Ingresa tu mail, te enviaremos instrucciones de recuperación a la brevedad.</small>
                  <br />
                  <form>
                    <div className="input-field">
                      <input id="username" type="text" className="validate" defaultValue="" onChange={e => setUsername(e.target.value)} value={username} />
                      <label htmlFor="username">Correo</label>
                    </div>
                    <br /><br />
                    <div>
                      <a href="#!" className="btn black forcewidth100 modal-trigger" onClick={recuperar} >RECUPERAR</a>
                    </div>
                  </form>
                </div>
              </div>
              :
              <div className="card-stacked">
                <div className="card-content">
                  <span className="card-title black-text text-darken-1"><b>Actualiza tu contraseña</b></span>
                  <small>Ingresa y valida tu nueva contraseña.</small>
                  <br />
                  <form>
                    <div className="input-field">
                      <input id="password" type="password" className="validate" defaultValue="" minLength="6" onChange={e => revisarContraseña(e.target.value, 'password')} />
                      <label htmlFor="password">Contraseña</label>
                      <span id="psswd" className="helper-text ht red-text"></span>
                    </div>
                    <div className="input-field">
                      <input id="passwordR" type="password" className="validate" defaultValue="" minLength="6" onChange={e => revisarContraseña(e.target.value, 'passwordR')} />
                      <label htmlFor="passwordR">Repetir Contraseña</label>
                      <span id="psswdR" className="helper-text ht red-text"></span>
                    </div>

                    <br /><br />
                    <div>
                      <a href="#!" className="btn black forcewidth100 modal-trigger" onClick={actualizar}>ACTUALIZAR</a>
                    </div>
                  </form>
                </div>
              </div>
          }

        </div>
      </div>
      <LogoutPopup></LogoutPopup>

    </div>


  )
}



export default Restablecer
