import { generarIceServers, enviarEvento, refreshSesion } from './api';

/*--- Generar iceServers---*/
export const obtenerIceServers = () => {
    return generarIceServers()
}

export const evento = (paso, tipo, data, finished, configurationObject) => {
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) {
        fecha = `${year}-0${month}-${day}_${hour}:${min}:${seconds}`
    } else {
        fecha = `${year}-${month}-${day}_${hour}:${min}:${seconds}`
    }
    let uuidUser = configurationObject.uuidUser;
    let uuidTrx = configurationObject.transaccion;
    let apikeyOtorgante = localStorage.getItem("apikey");
    let uuidOtorgante = configurationObject.uuidOtorgante;
    let objeto = {
        uuid_clt: uuidUser,
        uuid_trx: uuidTrx,
        uuid_oto: uuidOtorgante,
        apikey: apikeyOtorgante,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }
    enviarEvento(uuidUser, uuidTrx, apikeyOtorgante, objeto).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

/*--- Renovar token de sesión */
export const renovarToken = (token) => {
    return refreshSesion(token).then(({ status, data }) => {
        if (status === 200) {
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("token_refresh", data.refresh_token);
        }
    }).catch((error) => {
        console.log({ error });
    })
}

/*--- Mapear evento ---*/
export const mapearEvento = (event) => {
    let objetoEvento = {
        isTrusted: event?.isTrusted,
        type: event?.type,
        currentTarget: event?.currentTarget,
    }
    return objetoEvento;
}