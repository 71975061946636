import React from 'react';

const Spinner = (props) => {
  let statusLoading = props.state;
  let clase = '';

    if (statusLoading) {
      clase = "loader_container";
    } else {
      clase = "loader_container nodisply";
    }
  return (
    <div className={clase}>
            <div className="ispinner ispinner-large">
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
            </div>
        </div>
  );
}

export default Spinner;