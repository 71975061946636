import axios from 'axios';

const host = process.env.REACT_APP_URL;
const servicio = process.env.REACT_APP_SERVICE;
const bucket = process.env.REACT_APP_BUCKET;
const token = process.env.REACT_APP_TOKEN_EVENTS;

const endpoints = {
    files: `${host}/v1/sec_${servicio}file`,
    otorgante: `${host}/v1/sec_${servicio}otorgante_general/api/otorgante/`,
    otorganteParams: `${host}/v1/sec_${servicio}otorgante_general/api/otorganteParams/`,
    usuarios: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    addusuarios: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    score: `${host}/v1/sec_${servicio}query_scores`,
    login: `${host}/v1/sec_${servicio}otorgante_general_login_public`,
    refresh: `${host}/v1/sec_${servicio}otorgante_general_refresh_public`,//oauth/access_token_public`,
    scoreTotal: `${host}/v1/sec_${servicio}query_scores/0`,
    scoreCycle: `${host}/v1/sec_${servicio}query_cycle/7d5aa50d-395f-47dd-9272-70f5815b4091`,
    rules: `${host}/v1/sec_${servicio}rules/`,
    infoUser: `${host}/v1/sec_${servicio}otorgante_general_usuario_info`,
    correoConfirmacion: `${host}/v1/sec_${servicio}mail_py_password`,
    cambiarPass: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`, //id otorgante_general/api/usuario/313
    reset: `${host}/v1/sec_${servicio}otorgante_general_reset_password/`,
    delusuarios: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    getInfoCliente: `${host}/v1/sec_${servicio}otorgante_general_cliente_info`,
    sendMail: `${host}/v1/sec_${servicio}mail_py_change_rules_mail`,
    /* servicios */
    getUsuarios: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    getAgenteInfo: `${host}/v1/sec_${servicio}otorgante_general_agente_info/`,
    getTurnoAgente: `${host}/v1/sec_${servicio}otorgante_general/api/turnoAgente/`,
    getTurnos: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    asignarAgenteATurno: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    finalizarAgenteATurno: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    compararRostro: `${host}/v1/sec_${servicio}identification_face_comparison`,
    getDocuments: `${host}/v1/sec_${servicio}proccessdocuments`,
    getDocumentsZip: `${host}/v1/sec_${servicio}proccessdocuments_zip`,
    ine: `${host}/v1/sec_${servicio}valid_ine`,
    renapo: `${host}/v1/sec_${servicio}valid_curp`,
    sendVideo: `${host}/v1/sec_${servicio}file`,
    generateIceServers: `${host}/v1/sec_${servicio}turn`,
    sendEvent: `${host}/v1/sec_${servicio}events`,
};

/*Sesion*/
export function initSesion(username, password) {
    return axios({
        method: 'post',
        url: endpoints.login,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            username: username,
            password: password
        }
    })
}

export function getInfo(username) {
    return axios({
        method: 'get',
        url: endpoints.infoUser,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            usuario: username
        }
    })
}

export function refreshSesion(r_token) {
    return axios({
        method: 'post',
        url: endpoints.refresh,
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            refresh_token: r_token,
            grant_type: "refresh_token"
        }
    })
}

/** Turnos video llamada */

export function obtenerInfoAgente() {
    return axios({
        method: 'get',
        url: endpoints.getAgenteInfo,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    })
}

export function tomarLLamada() {
    return axios({
        method: 'get',
        url: endpoints.getTurnoAgente,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    })
}

export function obtenerListaTurnos(statusTurno) {
    return axios({
        method: 'get',
        url: endpoints.getTurnos,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            idStatusTurno: statusTurno
        },
    })
}

export function asignarLLamada() {
    return axios({
        method: 'put',
        url: endpoints.asignarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idAgente": localStorage.getItem("id_user"),
            "idStatusTurno": "ASIGNADO"
        }
    })
}

export function finalizarLLamada() {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idStatusTurno": "TERMINADO"
        }
    })
}

export function enviarVeredicto(veredicto) {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "veredicto": veredicto
        }
    })
}

export function obtenerValidaciones() {
    return axios({
        method: 'get',
        url: endpoints.getTurnos + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

/*USER*/

export function obtenerUsuarios() {

    return axios({
        method: 'get',
        url: endpoints.getUsuarios,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        params: {
            limit: 100,
        }
    })
}

export function agregarUsuarios(user, pass, email, rol, status) {
    let habilitar = true;
    if (status !== "true")
        habilitar = false;
    return axios({
        method: 'post',
        url: endpoints.addusuarios,
        headers: {

            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            username: user,
            password: pass,
            enabled: habilitar,
            accountExpired: false,
            accountLocked: false,
            passwordExpired: false,
            email: email,
            roles: [
                rol
            ]
        }
    })
}

export function actualizarContrasena(iduser, pass) {

    return axios({
        method: 'PUT',
        url: endpoints.cambiarPass + iduser,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            password: pass,
            passwordChanged: true
        }
    })
}

export function enviarConfimacion(correoUser, nombreCorto, p, id) {
    var data = new FormData();
    data.append("user_login", correoUser);
    data.append("nombre_corto", nombreCorto);
    data.append("password", p);
    data.append("idUsuario", id);
    var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    var diasSemana = new Array("Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado");
    var f = new Date();
    let fecha_actual = diasSemana[f.getDay()] + ", " + f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear();
    data.append("fecha_actual", fecha_actual);
    return axios({
        method: 'post',
        url: endpoints.correoConfirmacion,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: data
    });
}

export function actualizarUsuarios(id, user, pass, email, rol, status) {
    return axios({
        method: 'PUT',
        url: endpoints.addusuarios + id,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            username: user,
            password: pass,
            enabled: status,
            accountExpired: false,
            accountLocked: false,
            passwordExpired: false,
            email: email,
            roles: [
                rol
            ]
        }
    })
}

export function delteUsuarios(id) {
    return axios({
        method: 'DELETE',
        url: endpoints.delusuarios + id,
        headers: {

            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

export function resetUser(email_reset) {

    return axios({
        method: 'post',
        url: endpoints.reset,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            email: email_reset
        }
    })
}

/*Reglas*/
export function obtenerRules(uuid) {
    return axios({
        method: 'get',
        url: endpoints.rules + uuid,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

export function obtenerInfoC() {
    return axios({
        method: 'get',
        url: endpoints.getInfoCliente,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

export function guardarRules(json) {
    return axios({
        method: 'post',
        url: endpoints.rules,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: json
    })
}

export function sendMailRules(username, user_login, nombrePartner) {
    var formData = new FormData();
    formData.append('username', username);
    formData.append('user_login', user_login);
    formData.append('nombrePartner', nombrePartner);
    return axios({
        method: 'post',
        url: endpoints.sendMail,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: formData
    })
}

/* Otorgante */
export function obtenerParametrosOtorgante(idOtorgante) {
    var formData = new FormData();
    formData.append("idOtorgante", idOtorgante);

    return axios({
        method: 'get',
        url: endpoints.otorganteParams,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        params: {
            "idOtorgante": idOtorgante
        }
    })
}

export function obtenerCycle() {
    return axios({
        method: 'get',
        url: endpoints.scoreCycle,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

export function obtenerOtorgantes() {
    let json = JSON.stringify({ "idOtorgante": localStorage.getItem("apikey") })
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: json
    })
}

export function guardarOtorgantes(nombre, obsolete, data, id) {
    var formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('obsolete', obsolete);
    formData.append('data', data);
    formData.append("idUsuario", id);
    return axios({
        method: 'post',
        url: endpoints.otorgante,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey")
        },
        data: formData
    })
}

export function actualizarOtorgantes(id, nombre, obsolete, data) {
    var formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('obsolete', obsolete);
    formData.append('data', JSON.stringify(data));
    return axios({
        method: 'put',
        url: endpoints.otorgante + id,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
        },
        data: formData
    })

}

export function actualizarOtorgantesLook(id, data) {
    var formData = new FormData();
    formData.append('data', data);
    return axios({
        method: 'put',
        url: endpoints.otorgante + id,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
        },
        data: formData
    })
}

export function obtenerMaps(cp) {
    return axios({
        method: 'get',
        redirect: 'follow',
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
        },
        params: {
            address: '&components=country:MX|postal_code:' + cp,
            key: 'AIzaSyCEY7oAX8XYeUljD8G3NGPum2w6s166oBc'
        }
    })
}

/*SCORE*/

export function obtenerScores(otorgante) {

    return axios({
        method: 'get',
        url: endpoints.scoreTotal,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
            'otorgante': otorgante
        },
    })
}

export function obtenerScoresTotal() {
    return axios({
        method: 'get',
        url: endpoints.scoreTotal,
        headers: {
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey"),
        },
    })
}

/* COMPARAR ROSTRO */

export function compararRostro(uuidUser, base64Image) {

    return axios({
        method: 'post',
        url: endpoints.compararRostro,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx"),
            "token": localStorage.getItem("token")
        },
        data: {
            "uuidUser": uuidUser,
            "id_turno": localStorage.getItem("id_call"),
            "comparetoimage": base64Image,
            "transaccion": localStorage.getItem("clienteTrx"),
            "service_call": "identification",
            "bucket": bucket
        }
    })
}

/* DOCUMENTOS */
export function getDocuments(uuidUser) {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey")
        },
        data: {
            uuidUser: uuidUser
        }
    })
}

export function getDocumentsZip(uuidUser) {
    return axios({
        method: 'post',
        url: endpoints.getDocumentsZip,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': localStorage.getItem("clienteTrx"),
            'apikey': localStorage.getItem("apikey")
        },
        data: {
            uuidUser: uuidUser
        }
    })
}

/* RENAPO */
export function obtenerValidacionRenapo(uuidUser, turnoId) {
    return axios({
        method: 'get',
        url: endpoints.renapo,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            'transaccion': localStorage.getItem("clienteTrx")
        },
        params: {
            turn: turnoId,
            uuid: uuidUser
        }
    })
}

export function enviarValidacionRenapo(uuidUser) {
    return axios({
        method: 'post',
        url: endpoints.renapo,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'apikey': localStorage.getItem("apikey")
        },
        params: {
            uuid: uuidUser
        }
    })
}

/* INE */
export function obtenerValidacionIne(uuidUser, turnoId) {
    return axios({
        method: 'get',
        url: endpoints.ine,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),

            'apikey': localStorage.getItem("apikey")
        },
        params: {
            turn: turnoId,
            uuid: uuidUser
        }
    })
}

export function enviarValidacionIne(uuidUser) {
    return axios({
        method: 'post',
        url: endpoints.getDocumentsZip,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("clienteTrx"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'apikey': localStorage.getItem("apikey")
        },
        data: {
            uuidUser: uuidUser
        }
    })
}

export function enviarVideo(file) {
    var data = new FormData();
    let ruta = localStorage.getItem("otorganteUuid") + "/" + localStorage.getItem("clienteUuid") + "/videocall_agent"
    data.append("bucket", bucket);
    data.append("folder", ruta);
    data.append("uuid", localStorage.getItem("clienteUuid"));
    data.append("file", file, (new Date().getTime()) + "videocall_agent.mp4");
    return axios({
        method: 'post',
        url: endpoints.sendVideo,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("clienteTrx"),
            'cliente': localStorage.getItem("clienteUuid"),
            'apikey': localStorage.getItem("apikey")
        },
        data: data
    });
}
/*--- Actualización componente videollamada---*/
/* Generar ICE servers */

export const generarIceServers = () => {
    const o = {
        format: 'urls',
    }
    const bodyString = JSON.stringify(o);
    const apikey = localStorage.getItem("apikey");
    return axios({
        method: 'put',
        url: endpoints.generateIceServers,
        headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line max-len
            'Authorization': 'Basic ' + btoa('ortizs:64d00bac-3af0-11eb-abd8-0242ac150002'),
            'apikey': apikey,
        },
        data: bodyString,
    })
}


export function revisarEstadoTurno(idCall, transaccion) {
    return axios({
        method: 'get',
        url: endpoints.getTurnos + idCall,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

export function asignarLLamadaAgente(idCall, transaccion) {
    return axios({
        method: 'put',
        url: endpoints.asignarAgenteATurno + idCall,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idAgente": localStorage.getItem("id_user"),
            "idStatusTurno": "ASIGNADO"
        }
    })
}

export function finalizarLLamadaAgente(idCall, transaccion) {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + idCall,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idStatusTurno": "TERMINADO"
        }
    })
}

export function enviarVeredictoTurno(idCall, transaccion, veredicto) {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + idCall,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "veredicto": veredicto
        }
    })
}

export function obtenerValidacionesTurno(idCall, transaccion) {
    return axios({
        method: 'get',
        url: endpoints.getTurnos + idCall,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}


export function compararRostroCliente(idCall, uuidUser, transaccion, base64Image) {

    return axios({
        method: 'post',
        url: endpoints.compararRostro,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion,
            "token": localStorage.getItem("token")
        },
        data: {
            "uuidUser": uuidUser,
            "id_turno": idCall,
            "comparetoimage": base64Image,
            "transaccion": transaccion,
            "service_call": "identification",
            "bucket": bucket
        }
    })
}

/* RENAPO */
export function obtenerValidacionRenapoTurno(uuidUser, turnoId, transaccion) {
    return axios({
        method: 'get',
        url: endpoints.renapo,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            'transaccion': transaccion
        },
        params: {
            turn: turnoId,
            uuid: uuidUser
        }
    })
}

/* DOCUMENTOS */
export function getDocumentsClient(uuidUser, transaccion) {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': transaccion,
            'apikey': localStorage.getItem("apikey")
        },
        data: {
            uuidUser: uuidUser
        }
    })
}

export function getDocumentsZipClient(uuidUser, transaccion) {
    return axios({
        method: 'post',
        url: endpoints.getDocumentsZip,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'transaccion': transaccion,
            'apikey': localStorage.getItem("apikey")
        },
        data: {
            uuidUser: uuidUser
        }
    })
}

export const enviarEvento = (uuidUser, uuidTrx, apikeyOtorgante, objeto) => {
    //let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.sendEvent,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'transaccion': uuidTrx,
            'cliente': uuidUser,
            'apikey': apikeyOtorgante,
            'xc-token': token,
        },
        data: objeto
    });
}