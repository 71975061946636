import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from "react-router-dom";
import M from 'materialize-css';
import { refreshSesion } from '../../services/api.js';
function App() {
  
  const timeout = 1900*1000;
  const history = useHistory();
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)
  const [fecha, setFecha] = useState("")
  const [isTimeup, setTimeup] = useState(false)
  const [isToken, setIsToken] = useState(false)
  const [token, setToken] = useState('')
  const [remaining, setRemaining] = useState(timeout)
  const [token_refresh, setToken_Refresh] = useState('');

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const handleReset = () => reset()
  const handlePause = () => pause()
  const handleResume = () => resume()

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  useEffect(() => {
            const ye = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(lastActive);
            const mo = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(lastActive);
            const da = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(lastActive);
            setFecha(`${ye}-${mo}-${da}`)
  }, [lastActive])

  useEffect(() => {
    if(elapsed >= timeout){
        console.log("BYE");
        setTimeup(true);
        handlePause();
        setTimeout(() => {
          history.push('/index');
      }, 200);
    }else{
        if(elapsed != 0){
            let tiempo = timeout - elapsed;
            if(tiempo < 20000 && tiempo > 0){
                let acabo = timeout - (300000)//minutos de inactividad 5min = 300000
                if(remaining > acabo){
                    console.log("SEND TOKEN:",tiempo);
                    let rtoken = localStorage.getItem("token_refresh")
                    refreshSesion(rtoken).then((response) => {
                        if (response.status === 200) {
                            console.log("Se actualizo token");
                            setToken(response.data.access_token);
                            localStorage.setItem("token", response.data.access_token);
                            setToken_Refresh(response.data.refresh_token)
                            localStorage.setItem("token_refresh", response.data.refresh_token);
                            handleReset();//reinicia remaining y elapsed
                        }
                    }).catch(() => {
                        console.log("algun error");
                        M.toast({html: 'Se termino tiempo de sesión'});
                        setTimeup(true);
                        setTimeout(() => {
                          history.push('/index');
                      }, 200);
                    })
                }else{
                    console.log("BYE");
                    setTimeup(true);
                    handlePause();
                    M.toast({html: 'Se cerro sesión por inactividad'});
                    setTimeout(() => {
                      history.push('/index');
                  }, 200);
                }
            }
        }
    }
}, [elapsed])


  return (
    <div>
      {(isTimeup) ? 
        <div>
          "Ha expirado la sesisón"
        </div>
        :
        ""
      }
    </div>
  );
}

export default App;
