import React, { useState } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import {Table} from 'react-materialize';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { obtenerUsuarios, agregarUsuarios, actualizarUsuarios } from '../services/api.js';



const Base = () => {

    const history = useHistory();

    useEffect(() => {
      let user = localStorage.getItem("username")
      if (user === "") {
      }
      let to = localStorage.getItem("token")
      if (to === "") {
      }
      materializeStylos();
    }, [])


    const materializeStylos = () => {
      var elems = document.querySelectorAll('.modal');
      var el = document.querySelectorAll('select');
      var elemDP = document.querySelectorAll('.datepicker');
      var elemSN = document.querySelectorAll('.sidenav');
      M.Datepicker.init(elemDP, { container: 'body', format:"yyyy-mm-dd", onSelect: function(date) {}});
      M.Modal.init(elems, {});
      M.FormSelect.init(el, {});
      M.Sidenav.init(elemSN, {});
    }
    
    return (
        <div>
          <HeaderPage></HeaderPage>
          <div className="row">
            <br />
            <div className="col s12 m11 z-depth-3 mzauto extra_pdd">
            </div>
          </div>
          <LogoutPopup></LogoutPopup>
    
        </div>
    
    
      )
}



export default Base
